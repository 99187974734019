import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormControl, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';
import Logo from 'src/assets/funnelLoop/landing_logo.svg';
import Laptop from 'src/assets/funnelLoop/laptop.svg';
import Check from 'src/assets/funnelLoop/check.svg';
import LaptopBg from 'src/assets/funnelLoop/laptop-bg.svg';
import CenterImage from 'src/assets/funnelLoop/center-image.png';
import CornerImage from 'src/assets/funnelLoop/corner-image.png';
import CornerMobileImage from 'src/assets/funnelLoop/corner-mobile.png';
// import GoogleIcon from 'src/assets/funnelLoop/google-icon.png';
import { useBoundingClientRect } from 'src/hooks/use-bounding-client-rect';
import { useRef, useState } from 'react';
import { useResponsive } from 'src/hooks/use-responsive';
import { TextField, FormControlLabel, Checkbox, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useRouter } from 'src/routes/hooks';

const data = [
  {
    heading: 'Effortless Planning',
    description:
      'Input your parameters, and let Funneloop.ai handle the rest—no complex formulas or manual calculations',
  },
  {
    heading: 'Actionable Insights',
    description:
      'Gain valuable, data-driven insights instantly, empowering smarter marketing decisions',
  },
  {
    heading: 'Seamless Collaboration',
    description:
      'Easily share and iterate your plan with colleagues, ensuring quick feedback and alignment',
  },
];

export default function LandingPageNew() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    fullname: '',
    agreeToTerms: false,
    agreeMarketing: false,
  });

  const router = useRouter();

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    fullname: '',
    agreeToTerms: '',
    agreeMarketing: '',
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMesssage] = useState({
    severity: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    // Clear errors when the user starts typing
    setErrors({
      ...errors,
      [name]: '',
    });
  };
  const createAccount = async () => {
    const response = await fetch('https://api.simpool.io/onboarding/account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account_name: formData.email,
        account_admin_name: formData.fullname,
        account_admin_last_name: '',
        account_admin_email: formData.email,
        account_admin_password: formData.password,
        skip_reg_verification: true,
        send_reset_password_email: false,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json(); // Read response only once.
      throw new Error(errorData?.detail?.message || 'Account creation failed'); // Provide a meaningful error message.
    }

    const data = await response.json(); // Read the response body.
    return data;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let valid = true;
    let newErrors = {
      email: '',
      password: '',
      fullname: '',
      agreeToTerms: '',
      agreeMarketing: '',
    };

    // Validate email
    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = 'Password is required';
      valid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
      valid = false;
    }

    // Validate fullname
    if (!formData.fullname) {
      newErrors.fullname = 'Full name is required';
      valid = false;
    } else if (formData.fullname.length < 4) {
      newErrors.fullname = 'Full name must be at least 4 characters';
      valid = false;
    }

    // Validate agreeToTerms
    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = 'You must agree to the terms and privacy policy';
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      setLoading(true);
      try {
        // Step 1: Create Account
        const accountResponse = await createAccount();
        const accountId = accountResponse.id;

        // Step 2: Create Title
        const titleResponse = await createTitle(accountId);
        const titleId = titleResponse.id;

        // Step 3: Deploy Title
        await deployTitle(titleId);

        // Step 4: Poll for Deployment Status until it's "deployed"
        let deployStatus;
        do {
          deployStatus = await getDeployStatus(titleId);
          if (deployStatus.status !== 'deployed') {
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }
        } while (deployStatus.status !== 'deployed');

        setErrorMesssage({
          severity: 'success',
          message: 'Deployment completed successfully. Redirecting...',
        });
        setOpen(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        // Redirect the user after successful deployment
        window.location.href =
          'https://app.funneloop.ai/login?utm_source=funneloop-website&utm_campaign=planner';
      } catch (error: any) {
        console.error('Error during signup process:', error);
        setErrorMesssage({ severity: 'error', message: error.message });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const createTitle = async (accountId: number) => {
    const response = await fetch('https://api.simpool.io/onboarding/title', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formData.email,
        account_id: accountId,
        title_type: 4,
        icon: 'not_set',
      }),
    });

    if (!response.ok) {
      throw new Error(`Title creation failed: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  };

  // Helper function to deploy title
  const deployTitle = async (titleId: number) => {
    const response = await fetch(`https://api.simpool.io/onboarding/title/${titleId}/schema`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Deploy title failed: ${response.statusText}`);
    }
  };

  // Helper function to get deploy status
  const getDeployStatus = async (titleId: number) => {
    const response = await fetch(
      `https://api.simpool.io/onboarding/title/${titleId}/deployment-job`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Get deploy status failed: ${response.statusText}`);
    }
    return await response.json();
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const mdUp = useResponsive('up', 'md');

  const mdDown = useResponsive('down', 'md');

  const container = useBoundingClientRect(containerRef);

  const offsetLeft = container?.left;

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={errorMessage.severity as any}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage.message}
        </Alert>
      </Snackbar>
      <Box>
        <Grid sx={{ paddingInline: { lg: '100px', xl: '100px' } }} container>
          {/* Left Side */}
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: { xs: 0, md: 2 } }}>
              {/* Logo */}
              <Box
                sx={{
                  mb: 2,
                  width: { xs: '100px', md: '130px' },
                  height: { xs: '100px', md: '125px' },
                  mt: { xs: 2 },
                  ml: { xs: 3 },
                }}
              >
                <img src={Logo} alt="Logo" />
              </Box>
              {/* Image (Desktop View) */}
              <Box sx={{ marginLeft: '50px', marginTop: '40px', transform: 'scale(1.25)' }}>
                <Box
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    width: '450px',
                    height: '350px',
                    textAlign: 'center',
                    marginTop: '-80px',
                    backgroundImage: `url(${LaptopBg})`,
                    backgroundSize: '95% 140%',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-51%, -47%)',
                      maxWidth: '77%',
                      minHeight: '400px',
                    }}
                    src={Laptop}
                    alt="Main Image"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 5,
                  display: { xs: 'block', md: 'none', lg: 'none' },
                  zIndex: -1,
                  transform: 'scale(1.1)',
                }}
              >
                <img src={CornerMobileImage} alt="Corner mobile" style={{ maxWidth: '100%' }} />
              </Box>
              {/* Big Text */}
              <Typography
                sx={{
                  mb: 2,
                  marginTop: { xs: '-20px', md: '50px', lg: '50px', xl: '50px' },
                  fontWeight: 700,
                  paddingInline: { xs: 1 },
                  lineHeight: '45px',
                  fontSize: { xs: '35px', md: '47px', lg: '47px', xl: '47px' },
                  color: '#191724',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                FunneLoop: AI-Powered <br></br> Marketing Plans in Seconds
              </Typography>

              {/* Small Text */}
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  fontWeight: 'normal',
                  lineHeight: '23px',
                  paddingInline: { xs: 1 },
                  fontSize: '18px',
                  color: '#637382',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Say goodbye to complex spreadsheets - Build your marketing plan in just 30 seconds
                with AI-driven insights
              </Typography>

              {/* Form (Mobile View) */}
              <Box
                sx={{
                  background: 'linear-gradient(135deg, #38BDFE, #0C96D8)',
                  color: 'white',
                  padding: { xs: 2, md: 4 },
                  position: 'relative',
                  width: '100%',
                  minHeight: '900px !important',
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '28px',
                    marginTop: '10px',
                    marginBottom: '20px',
                    fontWeight: '900',
                  }}
                >
                  Optimization Starts Here
                </Typography>
                {/* Form Fields */}
                <Box sx={{ paddingInline: '15px' }} component="form" onSubmit={handleSubmit}>
                  <TextField
                    sx={{
                      mb: 2,
                      backgroundColor: 'white',
                      borderRadius: 2,
                      '& .MuiInputBase-input': {
                        color: 'black',
                      },
                    }}
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    fullWidth
                  />
                  <TextField
                    sx={{
                      mb: 2,
                      letterSpacing: '1px',
                      backgroundColor: 'white',
                      borderRadius: 2,
                      '& .MuiInputBase-input': {
                        color: 'black',
                      },
                    }}
                    type="password"
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                  />
                  <TextField
                    sx={{
                      mb: 2,
                      backgroundColor: 'white',
                      borderRadius: 2,
                      '& .MuiInputBase-input': {
                        color: 'black',
                      },
                    }}
                    label="Full Name"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    error={!!errors.fullname}
                    helperText={errors.fullname}
                    fullWidth
                  />
                  {/* Checkbox */}
                  <FormControl component="fieldset" error={!!errors.agreeToTerms}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'black',
                            },
                          }}
                          name="agreeToTerms"
                          checked={formData.agreeToTerms}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '16px' }}>
                          I agree to{' '}
                          <a style={{ color: 'white' }} href="#">
                            privacy policy
                          </a>{' '}
                          &{' '}
                          <a style={{ color: 'white' }} href="#">
                            terms
                          </a>
                        </Typography>
                      }
                      sx={{ color: 'white' }}
                    />
                    {errors.agreeToTerms && <FormHelperText>{errors.agreeToTerms}</FormHelperText>}
                  </FormControl>

                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'black',
                            },
                          }}
                          name="agreeMarketing"
                          checked={formData.agreeMarketing}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '16px' }}>
                          I accept receiving marketing materials
                        </Typography>
                      }
                      sx={{ color: 'white', mb: 2 }}
                    />
                  </FormControl>

                  {/* Buttons */}

                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '18px',
                      marginTop: '10px',
                      marginBottom: '10px',
                      fontWeight: '600',
                    }}
                  >
                    Boost your ROI now!
                  </Typography>
                  <Button
                    disabled={!formData.agreeToTerms}
                    sx={{
                      height: '50px',
                      borderRadius: '50px',
                      filter: 'drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ))',
                      color: 'black',
                      fontSize: '18px',
                      background: 'linear-gradient(180deg, #ffb70c 0.00%,#ffcb4f 100.00%)',
                      mb: 2,
                    }}
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    {loading ? <CircularProgress size={25} /> : 'Sign Up'}
                  </Button>

                  <Typography sx={{ marginTop: '20px', textAlign: 'center', fontSize: '15px' }}>
                    Already have an account?{' '}
                    <a style={{ color: 'white' }} href="https://app.funneloop.ai/login">
                      Sign in
                    </a>
                  </Typography>

                  {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <Divider sx={{ flexGrow: 1, border: '0.5px solid white' }} />
                    <Box sx={{ px: 2, fontWeight: '700', fontSize: '22px' }}>or</Box>
                    <Divider sx={{ flexGrow: 1, border: '0.5px solid white' }} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      sx={{
                        height: '50px',
                        borderRadius: '50px',
                        filter: 'drop-shadow(0px 3px 3px rgba(0,0,0,0.16))',
                        color: 'black',
                        fontSize: '18px',
                        backgroundColor: 'white',
                        mb: 2,
                        width: '80%',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#E3F2FD',
                        },
                        '&:active': {
                          backgroundColor: '#BBDEFB',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ transform: 'scale(0.7)' }}
                          src={GoogleIcon}
                          alt="Google Icon"
                        />{' '}
                        <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                          Sign in with Google
                        </Typography>
                      </Box>
                    </Button>
                  </Box> */}
                </Box>
              </Box>

              <Box sx={{ marginTop: '-200px', transform: 'scale(0.7)' }}>
                <Box
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    width: '450px',
                    height: '350px',
                    textAlign: 'center',
                    marginTop: '-80px',
                    backgroundImage: `url(${LaptopBg})`,
                    backgroundSize: '95% 140%',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      top: '60%',
                      left: '50%',
                      transform: 'translate(-51%, -47%)',
                      maxWidth: '77%',
                      minHeight: '400px',
                    }}
                    src={Laptop}
                    alt="Main Image"
                  />
                </Box>
              </Box>

              {/* Tick Boxes */}
              <Box sx={{ transform: { md: 'scale(0.8)', lg: 'scale(1)', xl: 'scale(1)' } }}>
                <Box
                  sx={{
                    display: { md: 'flex-col', lg: 'flex', xl: 'flex' },
                    flexDirection: { xs: 'column', md: 'row' },
                    marginLeft: { md: '-40px', lg: 'unset' },

                    justifyContent: {
                      xs: 'center',
                      md: 'end',
                      lg: 'space-between',
                      xl: 'space-between',
                    },
                    alignItems: { xs: 'center' },
                    gap: { xs: 1, md: 0, lg: 2 },
                    marginTop: { xs: '0px', md: '220px', lg: '220px', xl: '220px' },
                    paddingInline: { xs: 2, md: 0, lg: 0 },
                  }}
                >
                  {data.map((item, index) => (
                    <Box>
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: { xs: 'center' },
                          marginBlock: { xs: 2, md: 1 },
                        }}
                      >
                        <img
                          style={{ width: '30px', height: '30px', marginBottom: '20px' }}
                          src={Check}
                          alt="check"
                        />

                        <Typography
                          sx={{
                            fontFamily: 'Barlow',
                            fontSize: '23px',
                            fontWeight: '500',
                            width: '250px',
                            fontStyle: 'normal',
                            textAlign: { xs: 'center', md: 'left' },
                            color: '#191724',
                          }}
                        >
                          {item.heading}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: '6px',
                            fontFamily: 'Barlow',
                            marginRight: '28px',
                            fontSize: '17px',
                            fontWeight: '450',
                            width: '220px',
                            fontStyle: 'normal',
                            textAlign: { xs: 'center', md: 'left' },
                            color: '#191724',
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' },
                  alignItems: 'center',
                  poition: 'fixed',
                  justifyContent: 'center',
                  paddingInline: '10px',
                  paddingBlock: '25px',
                  marginTop: '10px',
                  boxShadow: '0px 10px 20px rgba(0, 0, 0, 1)',
                  width: '100%',
                }}
              >
                © 2024-2025. All right Reserved
              </Box>
            </Box>
          </Grid>

          {/* Right Side (Desktop View) */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: 'linear-gradient(135deg, #38BDFE, #0C96D8)',
                color: 'white',
                padding: { xs: 2, md: 4 },
                position: 'fixed',
                right: '100px',
                width: '450px',
                minHeight: '100vh',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '34px',
                  marginTop: '20px',
                  marginBottom: '30px',
                  fontWeight: '900',
                }}
              >
                Optimization Starts Here
              </Typography>
              {/* Form Fields */}
              <Box sx={{ paddingInline: '15px' }} component="form" onSubmit={handleSubmit}>
                <TextField
                  sx={{
                    mb: 2,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  type="email"
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                />
                <TextField
                  sx={{
                    mb: 2,
                    letterSpacing: '1px',
                    backgroundColor: 'white',
                    borderRadius: 2,
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  type="password"
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  fullWidth
                />
                <TextField
                  sx={{
                    mb: 2,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                  label="Full Name"
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                  error={!!errors.fullname}
                  helperText={errors.fullname}
                  fullWidth
                />

                {/* Checkbox */}
                <FormControl component="fieldset" error={!!errors.agreeToTerms}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '16px' }}>
                        I agree to{' '}
                        <a style={{ color: 'white' }} href="#">
                          privacy policy
                        </a>{' '}
                        &{' '}
                        <a style={{ color: 'white' }} href="#">
                          terms
                        </a>
                      </Typography>
                    }
                    sx={{ color: 'white' }}
                  />
                  {errors.agreeToTerms && <FormHelperText>{errors.agreeToTerms}</FormHelperText>}
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'black',
                          },
                        }}
                        name="agreeMarketing"
                        checked={formData.agreeMarketing}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '16px' }}>
                        I accept receiving marketing materials
                      </Typography>
                    }
                    sx={{ color: 'white', mb: 2 }}
                  />
                </FormControl>

                {/* Buttons */}

                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: '600',
                  }}
                >
                  Boost your ROI now!
                </Typography>
                <Button
                  disabled={!formData.agreeToTerms}
                  sx={{
                    height: '50px',
                    borderRadius: '50px',
                    filter: 'drop-shadow(0px 3px 3px rgba(0,0,0,0.16 ))',
                    color: 'black',
                    fontSize: '18px',
                    background: 'linear-gradient(180deg, #ffb70c 0.00%,#ffcb4f 100.00%)',
                    mb: 2,
                  }}
                  variant="contained"
                  type="submit"
                  fullWidth
                >
                  {loading ? <CircularProgress size={25} /> : 'Sign Up'}
                </Button>

                <Typography sx={{ marginTop: '20px', textAlign: 'center', fontSize: '15px' }}>
                  Already have an account?{' '}
                  <a style={{ color: 'white' }} href="https://app.funneloop.ai/login">
                    Sign in here
                  </a>{' '}
                </Typography>

                {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                      marginBottom: '30px',
                    }}
                  >
                    <Divider sx={{ flexGrow: 1, border: '0.5px solid white' }} />
                    <Box sx={{ px: 8 }}>or</Box>
                    <Divider sx={{ flexGrow: 1, border: '0.5px solid white' }} />
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      sx={{
                        height: '50px',
                        borderRadius: '50px',
                        filter: 'drop-shadow(0px 3px 3px rgba(0,0,0,0.16))',
                        color: 'black',
                        fontSize: '18px',
                        backgroundColor: 'white',
                        mb: 2,
                        width: '50%',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#E3F2FD',
                        },
                        '&:active': {
                          backgroundColor: '#BBDEFB',
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ transform: 'scale(0.7)' }} src={GoogleIcon} />{' '}
                        <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                          Sign in with Google
                        </Typography>
                      </Box>
                    </Button>
                  </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            display: { xs: 'none', md: 'block' },
            zIndex: -1,
            transform: 'scale(0.9)',
          }}
        >
          <img src={CornerImage} alt="Corner 1" style={{ maxWidth: '100%' }} />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '54%',
            right: '34%',
            transform: 'translateY(-50%)',
            display: { xs: 'none', md: 'block' },
            zIndex: -1,
          }}
        >
          <img src={CenterImage} alt="Corner 2" style={{ maxWidth: '100%' }} />
        </Box>
      </Box>
    </>
  );
}
